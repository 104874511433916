import React from 'react'
import Landing from '../Component/Landing'

function MainLanding() {
  return (
    <div>
        <Landing/>
    </div>
  )
}

export default MainLanding