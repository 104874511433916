import React from 'react';
import { FaCopy } from 'react-icons/fa'; // Import copy icon
import gpay from '../Image/gpay.jpeg';  // Ensure this is the correct QR code image path

const upiID = 'ananduns612-4@okaxis';  // Updated UPI ID
const phoneNumber = '9048501884';  // Phone number

function Payment() {

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        alert(`${text} copied to clipboard`);
    };

    return (
        <div className='container'>
            <div className='row justify-content-center text-center'>
                <div className='col-12 col-md-6'>
                    <div className="card">
                        <div className="card-body pay_card">
                            <p>Give a hand for our short film.</p>

                            {/* Pay by UPI ID */}
                            <div className="box p-3 mb-3">
                                <p className="mb-1">Pay by UPI ID:</p>
                                <div className="d-flex justify-content-center align-items-center">
                                    <span>{upiID}</span>
                                    <FaCopy className="ml-2" style={{ cursor: 'pointer' }} onClick={() => handleCopy(upiID)} />
                                </div>
                            </div>

                            {/* Pay by Phone Number */}
                            <div className="box p-3 mb-3">
                                <p className="mb-1">Pay by Phone Number:</p>
                                <div className="d-flex justify-content-center align-items-center">
                                    <span>{phoneNumber}</span>
                                    <FaCopy className="ml-2" style={{ cursor: 'pointer' }} onClick={() => handleCopy(phoneNumber)} />
                                </div>
                            </div>

                            {/* Pay by Scanner */}
                            <div className="box p-3">
                                <p className="mb-1">Pay by Scanner:</p>
                                <img src={gpay} alt="UPI QR Code" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;
