import React from 'react'
import Header from './Header'
import SectionOne from './SectionOne'
import Payment from './Payment'
import Story from './Story'

function Landing() {
  return (
    <div>
        <Header/>
        <SectionOne/>
        <Payment/>
        <Story/>
    </div>
  )
}

export default Landing