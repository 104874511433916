import React from 'react';
import dir from '../../src/Image/dir.jpeg';
import dop from '../../src/Image/dop.jpeg';
import actone from '../../src/Image/actone.jpeg';
import acttwo from '../../src/Image/acttwo.jpeg';
import actthree from '../../src/Image/actthree.jpeg';
import actfour from '../../src/Image/actfour.jpeg';
import actfive from '../../src/Image/actfive.jpeg';
import actsix from '../../src/Image/actsix.jpeg';
import actseven from '../../src/Image/actseven.jpeg'

function Story() {
    return (
        <div className='container'>
            <div className='row text-center mb-4'>
                <div className='col-12'>
                    <h3>One line Story:</h3>
                    <div className='d-flex justify-content-center'>
                        <div className='col-12 col-md-8'>
                            <p className='text_story'>
                                A boy named Gokul is arrested for his sister's murder. But he knows that he is innocent. Gokul escapes after the funeral of his sister from police custody and goes to his friend for help. His friend and Gokul try to find out who the killer is. With the help of his friend's friend, who works for the police, they learn about the suspicious events that happened at Gokul's house that day and uncover the truth.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row text-center mb-4'>
                <div className='col-12'>
                    <h3>Our Crew</h3>
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 text-center mb-3'>
                            <img src={dir} className='img-fluid img_crew' alt="Director" />
                            <p>Direction: Anandu N S</p>
                        </div>

                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 text-center mb-3'>
                            <img src={dop} className='img-fluid img_crew' alt="DOP" />
                            <p>Cinematography: Amal Abraham</p>
                        </div>

                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 text-center mb-3'>
                            <img src={actone} className='img-fluid img_crew' alt="Actor 1" />
                            <p>Actor: Sijo Antony</p>
                        </div>

                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 text-center mb-3'>
                            <img src={acttwo} className='img-fluid img_crew' alt="Actor 2" />
                            <p>Actor: Cheraman Durai</p>
                        </div>

                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 text-center mb-3'>
                            <img src={actthree} className='img-fluid img_crew' alt="Actor 3" />
                            <p>Actor: Sandeep</p>
                        </div>

                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 text-center mb-3'>
                            <img src={actfour} className='img-fluid img_crew' alt="Actor 4" />
                            <p>Actor: Gowtham Vijay</p>
                        </div>

                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 text-center mb-3'>
                            <img src={actfive} className='img-fluid img_crew' alt="Actor 5" />
                            <p>Actor: Hari Krishnan</p>
                        </div>

                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 text-center mb-3'>
                            <img src={actsix} className='img-fluid img_crew' alt="Actor 6" />
                            <p>Actor: Aalok P Nair</p>
                        </div>

                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 text-center mb-3'>
                            <img src={actseven} className='img-fluid img_crew' alt="Actor 6" />
                            <p>Actor: Fahad</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row text-center'>
                <div className='col-12'>
                    <h3>Our Works</h3>
                    <p>Youtube Channel:</p>
                    <a href='https://www.youtube.com/@capricorn_cinemas-wz1cw'>https://www.youtube.com/@capricorn_cinemas-wz1cw</a>

                    <p>Instagram:</p>
                    <a href='https://www.instagram.com/capricorn_cinemas/'>https://www.instagram.com/capricorn_cinemas/</a>

                    <p>Actors Works:</p>
                    <a href='https://www.instagram.com/reel/C50Z6Gwy32D/?igsh=NW0zd3UxcmtvZTA2'>https://www.instagram.com/reel/C50Z6Gwy32D/?igsh=NW0zd3UxcmtvZTA2</a>
                </div>
            </div>
        </div>
    );
}

export default Story;
