import React from 'react'
import MainLanding from './Page/MainLanding'
import './App.css'

function App() {
  return (
    <div>
      <MainLanding/>
    </div>
  )
}

export default App