import React from 'react'

function Header() {
  return (
    <div className=''>
        <div className='row header_main text-center'>
            <div className='col '>
                <p className='mt-4 main_head'>Found Riser</p>
            </div>
        </div>
    </div>
  )
}

export default Header