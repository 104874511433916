import React from 'react';
import one from '../Image/one.png';

function SectionOne() {
  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-12'>
          <p className='text-center'>
            Crowd Fund Raising for Our Next SHORT FILM "3rd Day" by Anandu N S
          </p>
        </div>
      </div>

      <div className='row justify-content-center'>
        <div className='col-12 col-md-10 col-lg-8'>
          <img src={one} className='img-fluid image_banner' alt="Banner" />
        </div>
      </div>

      <div className='row justify-content-center'>
        <div className='col-12'>
          <p className='text-center text_story'>
            It's the story of a boy who finds the killer of his sister's murder
          </p>
        </div>
      </div>
    </div>
  );
}

export default SectionOne;
